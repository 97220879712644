// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-create-tsx": () => import("./../../../src/pages/create.tsx" /* webpackChunkName: "component---src-pages-create-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-lanterns-tsx": () => import("./../../../src/pages/lanterns.tsx" /* webpackChunkName: "component---src-pages-lanterns-tsx" */),
  "component---src-pages-nav-tsx": () => import("./../../../src/pages/nav.tsx" /* webpackChunkName: "component---src-pages-nav-tsx" */),
  "component---src-pages-view-lantern-tsx": () => import("./../../../src/pages/view-lantern.tsx" /* webpackChunkName: "component---src-pages-view-lantern-tsx" */)
}

