import React, { useReducer, createContext } from "react";

export enum BG_TRACK_ACTIONS {
  LOADED = "BG_TRACK_LOADED",
  TOGGLE = "TOGGLE_BG_TOGGLE",
}

export enum FIRE_CRACKING_SOUND_ACTIONS {
  STARTED_PLAYING = "FIRE_CRACKING_SOUND_STARTED_PLAYING",
}

export enum LANTERNS_DATA_ACTIONS {
  UPDATE_DATA = "UPDATED_LANTERNS_DATA",
}

export interface GlobalStateInterface {
  backgroundTrack: object;
  isBGTrackCurrentlyPlaying: boolean;
  lanternsData: [object] | [];
}

export const GlobalStateContext = createContext({});
export const GlobalDispatchContext = createContext({});

const initialState: GlobalStateInterface = {
  backgroundTrack: null,
  isBGTrackCurrentlyPlaying: false,
  lanternsData: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case BG_TRACK_ACTIONS.LOADED:
      return {
        ...state,
        backgroundTrack: action.payload,
      };
    case BG_TRACK_ACTIONS.TOGGLE:
      return {
        ...state,
        isBGTrackCurrentlyPlaying: !state.isBGTrackCurrentlyPlaying,
      };
    case LANTERNS_DATA_ACTIONS.UPDATE_DATA:
      return {
        ...state,
        lanternsData: action.payload,
      };
    case FIRE_CRACKING_SOUND_ACTIONS.STARTED_PLAYING:
      return {
        ...state,
        fireCrackingSound: action.payload,
      };

    default:
      throw new Error(`Unrecognized action type '${action.type}'`);
  }
};

export default function GlobalContextProvder({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>
        {children}
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  );
}
