module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"G-BLXM73EZ3B","head":false,"defer":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/assets/favicons/favicon.png","icons":[{"src":"src/assets/favicons/favicon-16x16.png","sizes":"16x16","type":"image/png"},{"src":"src/assets/favicons/favicon-32x32.png","sizes":"32x32","type":"image/png"},{"src":"src/assets/favicons/mstile-150x150.png","sizes":"150x150","type":"image/png"},{"src":"src/assets/favicons/apple-touch-icon.png","sizes":"180x180","type":"image/png"},{"src":"src/assets/favicons/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"src/assets/favicons/android-chrome-256x256.png","sizes":"256x256","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"c04cf4864f939a0642a5e2d2314bf907"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
